import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import useTrackStore from "./store/track/index";
import {useTrackComment,useDeleteTrack,useTrackImageUpload,useDeleteImage} from "./hooks/useAuthenticatedTrack";
import styled, {css} from 'styled-components';
import usePlayerStore from "./store/player/usePlayer";
import {Button, TextField, InputText, DropZone, Submenu, SubmenuGroup} from "./FormControls.js";
import Scrubber from "./Scrubber.js";
import CommentMarkers from "./CommentMarkers.js";
import Comments, {TextContainer} from "./Comments.js";
import {TextArea, SubmenuButton} from "./FormControls";
import { useForm } from "react-hook-form";
import {PageTheme, Heading1,Heading2, mediaMax, mediaMin} from './Theme';
import Icon from './Icons';
import { useHistory, useParams } from 'react-router-dom';
import useSessionStore from "./store/session";
import useRecaptcha from "./hooks/useRecaptcha";
import {useUpdateImages} from "./hooks/useAuthenticatedTrack";
import RecaptchaDisclaimer from "./RecaptchaDisclaimer";
import { setDocumentTitle } from "./App";
import { useTrackNotifications } from "./Tracks";
import useRaf from "@rooks/use-raf";
import ItemMenu from "./ItemMenu";
import DeleteButton from "./DeleteButton";
import { ReactSortable } from "react-sortablejs";
import LoadingSpinner, { DarkeningSpinner } from "./LoadingSpinner";
import usePopupStore from "./store/popup/index"

const Submit = styled.button`
  width: 50px;
  flex: 0 0 50px;
  text-align: center;
  background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
  border: 0px;
  fill: ${ PageTheme.colors.create };
  border-top-right-radius: ${ PageTheme.properties.ButtonBorderRadius };
  border-bottom-right-radius: ${ PageTheme.properties.ButtonBorderRadius };
`

const SubmitIcon = styled(Icon)`
  margin: auto;
`

const Form = styled.form`
  background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
  border-radius: ${ PageTheme.properties.ButtonBorderRadius };
  border: 1px solid ${ PageTheme.colors.create };
  margin-bottom: ${ PageTheme.properties.size4 };
  font-size: 18px;
  cursor: default;
  display: flex;
  flex-grow: 1;
  min-height: 192px;
`

const SubmenuGroupHidingInFullscreenMode = styled(SubmenuGroup)`
  ${mediaMin('large', css`
    display: none;
  `)}
`

const StyledButton = styled(Button)`
  margin: ${ PageTheme.properties.size2 };

  background-color: ${ props => props.isActive ? PageTheme.colors[props.color] : null} !important;
  background: linear-gradient(
    0deg,
    ${ props => props.isActive ? PageTheme.colors[props.color] : null} 15%,
    ${ PageTheme.colors.ButtonBackground } 15%
  );

  display: ${props => props.visible?'flex':'none'};
  ${props => props.disabled ? css`
    color: ${PageTheme.colors.inactive};
    svg {
      fill: ${PageTheme.colors.inactive};
    }
  `:''}
`

const SyncButtonContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  
  button {
    height: 48px;
  }
  
  button:not(:last-child)
  {
    margin-right: ${PageTheme.properties.size3};
  }
`

const ButtonContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
`
const PlaylistButtons = styled.div`
  display: inline-flex;
`
const PlaylistButtonContainer = styled(ButtonContainer)`
  & > *:not(:last-child){
    margin-right: 4px;
  }
  margin-right: 16px;
`

const Container = styled.div`
    padding-top: 32px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    ${mediaMax('small', css`
      padding-top: 16px;
    `)}
`

const AsideButtonContainer = styled.div`
    position: relative;
`

const StyledAsideButton = styled.button`
    background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
    border: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    fill: #FFF;
    outline: none;
    cursor: pointer;
`

const AsideButtonIcon = styled(Icon)`
  width: 25px;
`
const ReportContainer = styled.div`
  position: absolute;
  right: ${ PageTheme.properties.size4 };
  bottom: ${ PageTheme.properties.size4 };
  max-width: 500px;
`
const StyledReportForm = styled.div`
  background: #FFFFFF22;
  padding: ${ PageTheme.properties.size5 };
  border-radius: ${ PageTheme.properties.size2 };
`
const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 0px;
  top: -14px;
`
const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: ${ PageTheme.properties.size4 };
  flex-grow: 1;
  min-height: 192px;
`

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 38%;
  margin-right: ${ PageTheme.properties.size5 };
	${mediaMax('small', css`
    display: ${props => props.visible ? 'initial' : 'none'};
    width: 100%;
    margin-right: ${ PageTheme.properties.size0 };
  `)}
  overflow: hidden;
  position: relative;
`
const Meta = styled.div`
padding-right: ${ PageTheme.properties.size2 };
display: block;
  overflow-y: auto;
  height: 100%;
`
const CommentContainer = styled.div`
  flex-grow: 1;
`

const Description = styled.div`
  margin-top: auto;
  padding-top: ${PageTheme.properties.size4};
	${mediaMax('small', css`
    padding-top: ${PageTheme.properties.size3};
  `)}
`

const CommentPanel = styled.div`
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  min-height: 192px;
  flex-shrink: 0;
`
const CurrentTrack = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMin('large', css`
    flex-direction: row;
    &:not(:first-child){
      margin-top: 16px;
    }
  `)}
  ${mediaMax('small', css`
    margin-top: 16px;
  `)}
`
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaMax('small', css`
    margin-top: 8px;
    margin-right: 64px;
  `)}
`
const PlaylistHeading = styled.div`
  ${mediaMax('small', css`
    margin-right: 64px;
  `)}
`

const ImageContainer = styled.div`
  display: block;
  margin-bottom: ${ PageTheme.properties.size4 };
  opacity: 0.8;
  cursor: ${props => props.editActive ? 'move' : 'pointer'};
  font-size: 18px;
  position: relative;
  text-decoration: none;

  &:hover{
    opacity: 1;
  }
`


const Image = styled.img`
  max-width: 100%;
  display: block;
`

const ImageCaption = styled.div`
  margin-top:  ${ PageTheme.properties.size3 };
`

const EditButtonGroup = styled.div`
  margin-left: 8px;
  display: flex;
`

const EditButton = styled(Button)`
  height: 40px;
  width: 40px;
  float: right;
  margin-left: 8px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`

const SaveCancelEditButton = styled(Button)`
  height: 40px;
  float: right;
  margin-left: 8px;
  
  ${mediaMax('small', css`
    span {
      display: none;
    }
  `)}
`

const EmptyContainer = styled.div``

const PopupImageContainer = styled.div.attrs(props => ({
  style: {
    width: props.scrollable ? `${props.width}px` : ''
  }
}))`
  ${props => !props.scrollable ? css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
  ` : ''}
`

const ImageControlsContainer = styled.div`
  display: inline-flex;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 99;
  background-color: #000;
  border-radius: ${ PageTheme.properties.ButtonBorderRadius };
  margin-right: auto;
  flex-shrink: 0;
  height: 40px;
  margin-bottom: 16px;
`

const PopupImage = styled.img`
  display: block;
  position: relative;
  ${props => props.fitsViewport ? css`
    max-width: 100%;
    max-height: 100%;
  ` : ''}
  overflow: hidden;
  object-fit: contain;
`
const StyledPopupImage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

function AsideButton({onClick,children}){
  return <AsideButtonContainer>
    <StyledAsideButton onClick={onClick}>{children}</StyledAsideButton>
    </AsideButtonContainer>
}

function CommentForm({visible}){
	const { register, handleSubmit, reset, errors } = useForm();
  const { pause,play,playing,runtime,setCurrentTime,setCommentActive,commentSynced,setCommentSynced,setCommentStart,setCommentEnd } = usePlayerStore();
  const { saveDisplayName, displayName } = useSessionStore();
  const user = useSessionStore(state => state.user);
  const { track } = useTrackStore();
  const displayNameInput = useRef();
  const textInput = useRef();
  const comment = useTrackComment();
  const challenge = useRecaptcha();
  const [playingSelection,setPlayingSelection] = useState(false);
  const [storedStartTime,setStoredStartTime] = useState();

  const togglePlaySelection = () => {
    if (playingSelection)
    {
      pause();
      setPlayingSelection(false);
    }
    else
    {
      setCommentSynced(false);
      setPlayingSelection(true);
  
      requestAnimationFrame(() => {
        let startTime = runtime.commentStart * runtime.duration;
        setStoredStartTime(startTime);
        setCurrentTime(startTime);
        play();
      });
    }
  }

  useRaf(() => {
    let startTime = runtime.commentStart * runtime.duration;
    let endTime = runtime.commentEnd * runtime.duration;
    
    if (startTime != storedStartTime)
    {
      setStoredStartTime(startTime);
      setCurrentTime(startTime);
    }

    if (runtime.currentTime > endTime)
    {
      pause();
      setPlayingSelection(false);
    }
  }, playingSelection)



	const onSubmit = async (data) => {
    const recaptchaToken = user ? null : await challenge();
    data = {...data, startTime: runtime.commentStart, endTime: runtime.commentEnd, track: track.uid, recaptchaToken}
    await comment(data);
    if(data.displayName){
      saveDisplayName(data.displayName)
    }
    reset();
    setCommentActive(false);
    setCommentSynced(true);
    setCommentStart(0);
    setCommentEnd(0);
  };
  
  useEffect(() => {
    if(visible){
      if(displayNameInput.current && !displayNameInput.current.value) displayNameInput.current.focus();
      else textInput.current.focus();
    }
  }, [visible])
  
  const commentSyncButtonsEnabled = playing;


  return <CommentPanel visible={visible}>
    <SyncButtonContainer>
      <Button
        onClick = {() => togglePlaySelection()}
        text = 'Play selection'
        iconID = "Play"
        color = "create"
        visible = {!playingSelection}
        isActive = {playingSelection}
      />
      <Button
        onClick = {() => togglePlaySelection()}
        text = 'Stop playing selection'
        iconID = "Pause"
        color = "create"
        visible = {playingSelection}
        isActive = {playingSelection}
      />

      <Button
        onClick = {() => setCommentSynced(true)}
        visible = {!commentSynced}
        text = 'Sync to playback'
        iconID = "ChainDisconnected"
        color = {commentSyncButtonsEnabled ? "create" : "inactive"}
        disabled = {!commentSyncButtonsEnabled}
      />
      <Button
        onClick = {() => setCommentSynced(false)}
        visible = {commentSynced}
        iconID = "ChainConnected"
        text = 'Sync to playback'
        color = {commentSyncButtonsEnabled ? "create" : "inactive"}
        disabled = {!commentSyncButtonsEnabled}
      />
    </SyncButtonContainer>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextContainer>
        {!user && <TextField defaultValue={displayName} setRef={displayNameInput} is="comment" errors={errors} hideLabel placeholder="Enter your name" label="Name" name="displayName" maxLength="20" register={register({ required: "Who are you?" })} />}
        <TextArea fullHeight setRef={textInput} is="comment" name="text" placeholder="Enter your comment" errors={errors} register={register({ required: "Don't you have anything to say?" })} />
      </TextContainer>

      <Submit type="submit">
        <SubmitIcon id="Plane" />
      </Submit>
      
      {!user && <RecaptchaDisclaimer />}
    </Form>
  </CommentPanel>
}

function TrackData({track,sortedComments,commentActive}){

  const maxComments = 4;
  const [commentMarkersExpanded,setCommentMarkersExpanded] = useState(sortedComments.length<maxComments);

  const checkWaveform = useTrackStore(state => state.checkWaveform)
  const loadTrack = useTrackStore(state => state.loadTrack)

  useEffect(() => {
    if(!track.waveform || !track.waveform.file){
      let attempts = 5;
      const timer = setInterval(async () => {
        const hasWaveform = await checkWaveform(track.uid)
        if(--attempts <= 0){
          console.warn('Waveform generation seemed to fail')
          clearInterval(timer);
          return;
        }

        if(hasWaveform){
          clearInterval(timer);
          await loadTrack(track.uid)
        }
      }, 10 * 1000)
      return () => clearInterval(timer);
    }
  }, [track.waveform && track.waveform.file])

  return <>
      <Scrubber track={track} style={{width:'100%',height:'128px',flexShrink:0}} marginTop={8} marginBottom={16} />
      <div style={{display:commentActive?'none':'block'}}>
        <CommentMarkers sortedComments={sortedComments} style={{width:'100%',flexShrink:0,flexGrow:0}} maxRows={commentMarkersExpanded?Infinity:maxComments} />
        {sortedComments.length > maxComments && <AsideButton onClick={() => setCommentMarkersExpanded(!commentMarkersExpanded)}>
          {commentMarkersExpanded ? <AsideButtonIcon id="SimpleArrowUp" /> : <AsideButtonIcon id="SimpleArrowDown" /> }
        </AsideButton>}
      </div>
  </>
}

function ReportForm({onClose}){
  const {user,report} = useSessionStore();  
  const challenge = useRecaptcha();
  const { register, handleSubmit, reset, errors } = useForm();
  const history = useHistory();

	const onSubmit = async (data) => {
    const recaptchaToken = user ? null : await challenge();
    const location = history.location.pathname;
    data = {...data, location, recaptchaToken};
    await report(data);
    reset();
  };
  
  return <StyledReportForm>
    <StyledCloseButton onClick={onClose} iconID="CloseX" color="destroy" iconOnly />
    <form onSubmit={handleSubmit(onSubmit)}>
      Why would you like to report this track?
      <TextArea name="reason" errors={errors} register={register({required:"You can not send empty reports."})}></TextArea>
      <StyledButton visible text="Report" iconID="Plane" color="destroy" type="submit" />
      {!user && <RecaptchaDisclaimer />}
    </form>
  </StyledReportForm>
}

function ZoomImage({images,index}){
  const image = images[index];
  const expandIcon = 'Expand'
  const expandText = 'Show original size'
  const collapseIcon = 'Collapse'
  const collapseText = 'Fit in viewport'
  const {setScrollable,scrollable,setPopupComponent} = usePopupStore();

  const toggleImageSize = useCallback(() => {
    setScrollable(!scrollable);
  }, [scrollable]);

  const openImageInNewTab = useCallback(() => {
    window.open(image.url, 'ImageFullscreen');
  }, [image]);
  const showImage = useCallback((dindex) => {
    setPopupComponent( <ZoomImage images={images} index={index + dindex} />, false);
  }, [index, images]);

  const prevImage = images[index - 1] && -1;
  const nextImage = images[index + 1] && 1;
  
  const hasImageList = images.length >= 2;

  return (
    <StyledPopupImage>

      <PopupImageContainer scrollable={scrollable} width={image.width}>
        <ImageControlsContainer>
          {hasImageList && <>
            <StyledButton onClick={() => showImage(prevImage)} iconID="ChevronLeft" visible color="neutral" disabled={!prevImage} />
            <StyledButton onClick={() => showImage(nextImage)} iconID="ChevronRight" visible  color="neutral" disabled={!nextImage} />
          </>}
          <StyledButton onClick={openImageInNewTab} iconID="ExternalLink" visible text='Open' color="neutral" formtarget="_blank" />
          <StyledButton onClick={toggleImageSize} visible text={scrollable ? collapseText : expandText} iconID={scrollable ? collapseIcon : expandIcon} color="neutral" />
        </ImageControlsContainer>
        <PopupImage fitsViewport={!scrollable} src={image.url} />      
      </PopupImageContainer>
      <Description>{image.caption}</Description>
    </StyledPopupImage>
  );
}

export default function Track(){
  const {track, playlist} = useTrackStore();
  const notifications = useTrackNotifications(track);
  const updateImages = useUpdateImages(track);
  const [showNotifications, setShowNotifications] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm();
  const [loading,setLoading] = useState(false);
  const {setPopupComponent} = usePopupStore();

  
  useEffect(() => {
      setShowNotifications(notifications.length !== 0);
  }, [notifications])
  
  useEffect(() => setDocumentTitle(track ? track.title : ''), [track])

  const {user} = useSessionStore();
  const deleteTrack = useDeleteTrack();
  const deleteImage = useDeleteImage();
  const {togglePause,playing,setCommentStart, setCommentEnd,runtime,setCommentSynced,setCommentActive,commentActive, trackDescriptionActive, setTrackDescriptionActive} = usePlayerStore();
  const [reportVisible,setReportVisible] = useState(false)
  const history = useHistory();
  const uploadImages = useTrackImageUpload();

  const sortedComments = useMemo(() => {
    if(!track) return [];
    const withNotification = comment => showNotifications ? notifications.find(notification => notification.comment.id === comment.id) : true;
    return track.comments.filter(withNotification).sort((a, b) => {
      if ( a.startTime < b.startTime ) return -1;
      if ( a.startTime > b.startTime ) return 1;
      return 0;
    })
  }, [track, notifications, showNotifications])

  const handleTrackDescriptionClick = useCallback(() => {
    if (trackDescriptionActive){
      setTrackDescriptionActive(false);
    } else {
      setTrackDescriptionActive(true);
      setCommentActive(false);
    }
  }, [trackDescriptionActive])

  const handleCommentClick = useCallback(() => {
    if(commentActive){
      setCommentActive(false);
      setCommentSynced(false);
    } else {
      setCommentActive(true);
      setTrackDescriptionActive(false);
      if(runtime.commentStart === 0) {
        setCommentSynced(true);
        setCommentEnd();
        setCommentStart();
      }
    }
  }, [commentActive])

  const handleDeleteClick = useCallback(async () => {
    if(!window.confirm('Track and comments will be irrecoverably lost. Are you sure you want to continue?')){
      return;
    }

    await deleteTrack(track)
    history.push(`/profile`);
  }, [track])

  const handleDeleteImageClick = useCallback(async (image) => {
    if(!window.confirm('Image will be irrecoverably lost. Are you sure you want to continue?')){
      return;
    }

    deleteImage(track.uid, image);
  }, [track, deleteImage])

  const handleFileSelection = useCallback(async (files) => {
    setUploadingImage(true);
    await uploadImages(track, files);
    setUploadingImage(false);
  }, [track, editActive]);
  
  const isMyTrack = user && track && user.id === track.users_permissions_user.id;
  
  const {id} = useParams();

  const {prevTrack,nextTrack} = useMemo(() => {
    if(!playlist) return {};
    const index = playlist.tracks.findIndex(t => t.id == id);
    if(index === -1) return {};
    const prev = playlist.tracks[index - 1];
    const next = playlist.tracks[index + 1];
    return {
      prevTrack: prev && prev.track && (() => history.push(`/playlist/${playlist.uid}/${prev.id}`)),
      nextTrack: next && next.track && (() => history.push(`/playlist/${playlist.uid}/${next.id}`)),
    };
  }, [playlist, id]);

  const Sortable = editActive ? ReactSortable : EmptyContainer;

  const [images,setImagesState] = useState([]);

  const handleImageContainerClick = (e, index) => {
    if(editActive){
      e.preventDefault();
    } else {
      setPopupComponent( <ZoomImage images={images} index={index} />, false);
    }
  }
  
  const setImages = useCallback((x) => {
    let changed = false;
    if(x.length !== images.length){
      changed = true;
    } else {
      for(let i = 0; i < images.length; i++){
        if(images[i].caption !== x[i].caption || images[i].id !== x[i].id){
          changed = true;
          break;
        }
      }
    }
    if(changed){
      setImagesState(x);
    }
  }, [images]);

  useEffect(() => {
    setImages(track.images);
  }, [track]);

  const setImageCaption = useCallback((index, caption) => {
    setImages(images.map((image, i) => index === i ? ({...image, caption}) : image));
  }, [images]);

  const cancel = useCallback(() => {
    setImages(track.images);
    setEditActive(false)
  }, [track]);

  const save = useCallback(async () => {
    setEditActive(false)
    setLoading(true);
    await updateImages(images);
    setLoading(false);
  }, [images]);

  const renderHeading = useCallback(() => {
    return <Heading>
      <Heading2 noMargin text={track.title} />
      {isMyTrack && <EditButtonGroup>
        {editActive && <SaveCancelEditButton iconID="Checkmark" text="Save" color="create" onClick={save} isActive={true} />}
        {editActive && <SaveCancelEditButton iconID="Undo" text="Cancel" color="destroy" onClick={cancel} isActive={true} />}
        {!editActive && <EditButton iconID="Edit" iconOnly color="create" onClick={() => setEditActive(true)} isActive={false} >{loading && <><DarkeningSpinner><LoadingSpinner /></DarkeningSpinner><Icon id ="Edit"/></>}</EditButton>}
      </EditButtonGroup>}
    </Heading>;
  }, [track, editActive, isMyTrack, loading, images])

  return track && track.file ? 
    <>
      <Container>
        {playlist
          ? <>
              {playlist.name && <PlaylistHeading><Heading1 text={playlist.name} /></PlaylistHeading>}
              
              <CurrentTrack>
                <PlaylistButtons>
                  <PlaylistButtonContainer>
                    <Button iconID="List" iconOnly color="audio" onClick={() => history.push(`/playlist/${playlist.uid}`)} />
                  </PlaylistButtonContainer>
                  <PlaylistButtonContainer>
                    <Button iconID="ChevronLeft" iconOnly color={prevTrack ? "audio" : "inactive"} disabled={!prevTrack} onClick={prevTrack} />
                    <Button iconID="ChevronRight" iconOnly color={nextTrack ? "audio" : "inactive"} disabled={!nextTrack} onClick={nextTrack} />
                  </PlaylistButtonContainer>
                </PlaylistButtons>
                {renderHeading()}
              </CurrentTrack>
            </>
          : renderHeading()
        }
        <Submenu>
          <SubmenuGroup>
            <ButtonContainer onClick={togglePause}>
              <SubmenuButton text="Play" iconID="Play" color="audio" visible={!playing} />
              <SubmenuButton text="Pause" iconID="Pause" color="audio" visible={playing} isActive={playing} />
            </ButtonContainer>
          </SubmenuGroup>
          <SubmenuGroup>
            <ButtonContainer onClick={handleCommentClick} >
              <SubmenuButton text="Comment" iconID="Comment" color="create" visible={true} isActive={commentActive} />
            </ButtonContainer>
          </SubmenuGroup>
          
          {isMyTrack
            ? (editActive && <>
                <SubmenuGroup>
                  <ButtonContainer onClick={handleDeleteClick} >
                    <SubmenuButton text="Delete" iconID="Trash" color="destroy" visible={isMyTrack} />
                  </ButtonContainer>
                </SubmenuGroup>
              </>)
            : <SubmenuGroup>
                <ButtonContainer onClick={() => setReportVisible(!reportVisible)}>
                  <SubmenuButton text="Report" color="destroy" iconID="AlertTriangle" />
                </ButtonContainer>
              </SubmenuGroup>
          }

          <SubmenuGroupHidingInFullscreenMode>
            <ButtonContainer onClick={handleTrackDescriptionClick}>
              <SubmenuButton text="Show info" iconID="Info" color="neutral" visible={true} isActive={trackDescriptionActive} />
            </ButtonContainer>
          </SubmenuGroupHidingInFullscreenMode>
        </Submenu>
        <TrackData track={track} sortedComments={sortedComments} commentActive={commentActive || trackDescriptionActive} />
            
        <SplitView>
          <MetaContainer visible={trackDescriptionActive}>
            <Meta>
              <Description>
                {editActive && <DropZone
                  text = "Drop your images here"
                  name = "images"
                  disabled = {uploadingImage}
                  loading = {uploadingImage}
                  color = 'create'
                  register = {register({ required: 'Choose the track you want to upload!' })}
                  errors = {errors}
                  accept = "image/png, image/gif, image/jpeg, image/webm"
                  multiple
                  onFileSelected = {handleFileSelection}
                  style={{marginBottom: PageTheme.properties.size5}}
                />}
                
                <Sortable group={{name:'images'}} list={images} setList={(v) => setImages(v)}>
                  {images.map((image, i) => <ImageContainer key={image.id} editActive={editActive} onClick={event => handleImageContainerClick(event, i)}>
                      {editActive && <ItemMenu><DeleteButton contrast onClick={ () => handleDeleteImageClick(image) } /></ItemMenu> }
                      <Image src={image.url} />
                      { !editActive && image.caption && <ImageCaption>{image.caption}</ImageCaption> }
                      { editActive && <InputText placeholder="Image caption" defaultValue={image.caption} onChange={(evt) => setImageCaption(i, evt.target.value)} /> }
                  </ImageContainer>)}
                </Sortable>
              </Description>
            </Meta>
          </MetaContainer>
          <CommentContainer>
            <CommentForm visible={commentActive} />
            <Comments showNotifications={showNotifications} showAllComments={() => setShowNotifications(false)} visible={!commentActive && !trackDescriptionActive} track={track} sortedComments={sortedComments} />
          </CommentContainer>
        </SplitView>
      </Container>
      <ReportContainer>
        {reportVisible && <ReportForm onClose={() => setReportVisible(false)} />}
      </ReportContainer>
    </>
    : 'not found'
}