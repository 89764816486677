import React, { useEffect, useState } from "react";
import useTrackStore from "./store/track/index";
import usePlayerStore from "./store/player/usePlayer";
import LoadingSpinner from "./LoadingSpinner";
import Track from "./Track";


export default function TrackLoader({match: {params: {uid}}}){
  const {loadTrack,track,error} = useTrackStore();
  const [loading, setLoading] = useState(false);
  const {setFile} = usePlayerStore();

  useEffect(async () => {
    if(!track || track.uid !== uid){
      setLoading(true);
      const foundTrack = await loadTrack(uid, true);
      if(foundTrack){
        setFile(foundTrack.file, `/track/${foundTrack.uid}`)
      }
      setLoading(false);
    }
  }, [uid])
  
  return loading ? <LoadingSpinner /> : track && track.file ? 
    <>
      <Track />
    </>
    : error ? 'Something went wrong fetching the track.' : 'not found'
}